import React from "react";
import ngDateFilter from "../../../ngDateFilter";
import FeedPostAvatar from "./FeedPostAvatar";
import FeedPostByline from "./FeedPostByline";
import FeedPostDropdown from "./FeedPostDropdown";

const TIMESTAMP_FORMAT = 'MMM d, y h:mm a';

const FeedHeader = ({ post, verb, actionUrls, cmd, description = false }) => {
  const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);

  return (
    <header className="d-flex gap-2">
      <FeedPostAvatar post={post} />
      <div className="Post-info flex-fill">
        <FeedPostByline post={post} verb={verb} description={description} />
        <time className="Post-time" dateTime={post.created_at}>
          {formattedDate}
        </time>
        <FeedPostDropdown post={post} actionUrls={actionUrls} cmd={cmd} />
      </div>
    </header>
  );
};

export default FeedHeader;
